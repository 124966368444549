// @ts-nocheck
import { observable, action } from "mobx";
import { v4 as uuid } from "uuid";

export interface ISnackbarNotification {
  timeout?: number;
  color?: "primary" | "success" | "danger" | "info" | "warning";
  title?: string | ReactElement;
  message: string | ReactElement;
  children?: any;
}

class NotificationsStore {
  @observable
  snackbarNotification = null;

  @action
  clearStore = () => {
    this.snackbarNotification = null;
  };

  @action
  setNotificationsList = (newValue) => {
    this.snackbarNotification = newValue;
  };

  addSnackbarNotification = ({
    timeout = 4000,
    color = "success",
    title,
    message,
    children,
  }: ISnackbarNotification) => {
    const token = uuid();
    this.setNotificationsList({
      token,
      timeout,
      color,
      title,
      message,
      children,
    });

    return token;
  };

  addFormHasErrorNotification = () => {
    this.addSnackbarNotification({
      message: "Existem erros no formulário. Por favor verifique.",
      timeout: 2000,
      color: "danger",
    });
  };

  addServerErrorNotification = () => {
    this.addSnackbarNotification({
      message:
        "Servidores estão sobrecarregados. Por favor tente novamente mais tarde..",
      timeout: 10000,
      color: "danger",
    });
  };
}

export default NotificationsStore;
