// @ts-nocheck
import React from "react";
import ReactAwesomeGravatar from "react-awesome-gravatar";
import { makeStyles } from "@material-ui/styles";

import { infoColor } from "../../assets/jss/app-js-styles";
const useStyles = makeStyles({
  base: ({ height, width, borderRadius }) => ({
    height,
    width,
    borderRadius,
    overflow: "hidden",
  }),
  initiaisContainer: {
    background: infoColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontWeight: "bold",
  },
});

function defaultInitials(name, { maxInitials }) {
  return name
    .split(/\s/)
    .map((part) => part.substring(0, 1).toUpperCase())
    .filter((v) => !!v)
    .slice(0, maxInitials)
    .join("");
}

const Gravatar = ({ name, email, size }) => {
  const classes = useStyles({
    height: size,
    width: size,
    borderRadius: size / 2,
  });

  if (email) {
    return (
      <div className={classes.base}>
        <ReactAwesomeGravatar
          email={email}
          options={{ size, default: "wavatar" }}
        >
          {(url) => <img alt={name || "Foto do usuário"} src={url} />}
        </ReactAwesomeGravatar>
      </div>
    );
  }

  return (
    <div
      className={`${classes.base} ${classes.initiaisContainer}`}
      style={{ height: size, width: size, borderRadius: size / 2 }}
    >
      {defaultInitials(name, { maxInitials: 3 })}
    </div>
  );
};

Gravatar.defaultProps = {
  size: 40,
};

export default Gravatar;
