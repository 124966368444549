// @ts-nocheck
import React, { forwardRef } from "react";

import { Button } from "../Button";
import Tooltip from "../Tooltip/Tooltip";

const CustomButton = forwardRef(
  ({ justIcon, isDisabled, color, style, icon, label, onClick }, ref) => {
    const button = (
      <Button
        justIcon={justIcon}
        disabled={isDisabled}
        onClick={onClick}
        color={color}
        small
        ref={ref}
        style={style}
      >
        <i
          className={`fas fa-${icon}`}
          style={{ marginRight: justIcon ? "inherit" : 6 }}
        />
        {!justIcon && label}
      </Button>
    );

    return justIcon ? <Tooltip title={label}>{button}</Tooltip> : button;
  }
);

const DeleteActionButton = forwardRef(({ canDelete, ...rest }, ref) => (
  <CustomButton
    ref={ref}
    {...rest}
    isDisabled={!canDelete}
    color="danger"
    icon="trash-alt"
    label="Apagar"
  />
));

DeleteActionButton.defaultProps = {
  canDelete: true,
};

const CloneActionButton = forwardRef((props, ref) => (
  <CustomButton
    ref={ref}
    {...props}
    color="info"
    icon="clone"
    label="Duplicar"
  />
));
const EditActionButton = forwardRef((props, ref) => (
  <CustomButton
    ref={ref}
    {...props}
    color="primary"
    icon="pen"
    label="Editar"
  />
));

const TableActionButton = ({ type, ...rest }) => {
  if (type === "delete") {
    return <DeleteActionButton {...rest} />;
  }
  if (type === "clone") {
    return <CloneActionButton {...rest} />;
  }

  if (type === "edit") {
    return <EditActionButton {...rest} />;
  }

  return <CustomButton {...rest} />;
};

TableActionButton.defaultProps = {
  justIcon: true,
  color: "primary",
};

export default TableActionButton;
