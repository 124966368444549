// @ts-nocheck

const drawerWidth = 260;

const drawerMiniWidth = 80;

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

const containerFluid = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  "&:before,&:after": {
    display: "table",
    content: '" "',
  },
  "&:after": {
    clear: "both",
  },
};

const container = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  "@media (min-width: 768px)": {
    width: "750px",
  },
  "@media (min-width: 992px)": {
    width: "970px",
  },
  "@media (min-width: 1200px)": {
    width: "1170px",
  },
  "&:before,&:after": {
    display: "table",
    content: '" "',
  },
  "&:after": {
    clear: "both",
  },
};

const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
};

const card = {
  display: "inline-block",
  position: "relative",
  width: "100%",
  margin: "25px 0",
  boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
  borderRadius: "6px",
  color: "rgba(0, 0, 0, 0.87)",
  background: "#fff",
};

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "300",
  lineHeight: "1.5em",
};

export const primaryColorWithOpacity = (opacity = 1.0) =>
  `rgba(46, 48, 145, ${opacity})`;
export const successColorWithOpacity = (opacity = 1.0) =>
  `rgba(165, 205, 57, ${opacity})`;
export const infoColorWithOpacity = (opacity = 1.0) =>
  `rgba(104, 201, 214, ${opacity})`;
export const warningColorWithOpacity = (opacity = 1.0) =>
  `rgba(248, 148, 6, ${opacity})`;
export const dangerColorWithOpacity = (opacity = 1.0) =>
  `rgba(246, 71, 71, ${opacity})`;

const primaryColor = primaryColorWithOpacity();
const successColor = successColorWithOpacity();
const infoColor = infoColorWithOpacity();
const warningColor = warningColorWithOpacity();
const dangerColor = dangerColorWithOpacity();
const roseColor = "#e91e63";
const grayColor = "#999999";
export const lightGrayColor = "#dad7d7";

const primaryBoxShadow = {
  boxShadow: `0 12px 20px -10px ${primaryColorWithOpacity(
    0.28
  )}, 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${primaryColorWithOpacity(
    0.2
  )}`,
};
const infoBoxShadow = {
  boxShadow: `0 12px 20px -10px ${infoColorWithOpacity(
    0.28
  )}, 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${infoColorWithOpacity(
    0.2
  )}`,
};
const successBoxShadow = {
  boxShadow: `0 12px 20px -10px ${successColorWithOpacity(
    0.28
  )}, 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${successColorWithOpacity(
    0.2
  )}`,
};
const warningBoxShadow = {
  boxShadow: `0 12px 20px -10px ${warningColorWithOpacity(
    0.28
  )}, 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${warningColorWithOpacity(
    0.2
  )}`,
};
const dangerBoxShadow = {
  boxShadow: `0 12px 20px -10px ${dangerColorWithOpacity(
    0.28
  )}, 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${dangerColorWithOpacity(
    0.2
  )}`,
};
const roseBoxShadow = {
  boxShadow:
    "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)",
};
const blackBoxShadow = {
  boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px #00000091",
};

// old card headers
const orangeCardHeader = {
  background: "linear-gradient(60deg, #ffa726, #fb8c00)",
  ...warningBoxShadow,
};
const greenCardHeader = {
  background: "linear-gradient(60deg, #66bb6a, #43a047)",
  ...successBoxShadow,
};
const redCardHeader = {
  background: "linear-gradient(60deg, #ef5350, #e53935)",
  ...dangerBoxShadow,
};
const blueCardHeader = {
  background: `linear-gradient(60deg, #26c6da, ${infoColor})`,
  ...infoBoxShadow,
};
const purpleCardHeader = {
  background: `linear-gradient(60deg, ${primaryColor}, #1E88E5)`,
  ...primaryBoxShadow,
};
// new card headers
const warningCardHeader = {
  background: "linear-gradient(60deg, #ffa726, #fb8c00)",
  ...warningBoxShadow,
};
const successCardHeader = {
  background: "linear-gradient(60deg, #66bb6a, #43a047)",
  ...successBoxShadow,
};
const dangerCardHeader = {
  background: "linear-gradient(60deg, #ef5350, #e53935)",
  ...dangerBoxShadow,
};
const infoCardHeader = {
  background: `linear-gradient(60deg, #26c6da, ${infoColor})`,
  ...infoBoxShadow,
};
const primaryCardHeader = {
  background: `linear-gradient(60deg, ${primaryColor}, #1E88E5)`,
  ...primaryBoxShadow,
};
const roseCardHeader = {
  background: "linear-gradient(60deg, #ec407a, #d81b60)",
  ...roseBoxShadow,
};
const blackCardHeader = {
  background: "linear-gradient(60deg, #3c4858, #000)",
  ...blackBoxShadow,
};

const cardActions = {
  margin: "0 20px 10px",
  paddingTop: "10px",
  borderTop: "1px solid #eeeeee",
  height: "auto",
  ...defaultFont,
};

const cardHeader = {
  margin: "-20px 15px 0",
  borderRadius: "3px",
  padding: "15px",
};

const defaultBoxShadow = {
  border: "0",
  borderRadius: "3px",
  boxShadow:
    "0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  padding: "10px 0",
  transition: "all 150ms ease 0s",
};

const title = {
  color: "#3C4858",
  textDecoration: "none",
  fontWeight: "300",
  marginTop: "30px",
  marginBottom: "25px",
  minHeight: "32px",
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  "& small": {
    color: "#777",
    fontSize: "65%",
    fontWeight: "400",
    lineHeight: "1",
  },
};

const cardTitle = {
  ...title,
  marginTop: "0",
  marginBottom: "3px",
  minHeight: "auto",
  "& a": {
    ...title,
    marginTop: ".625rem",
    marginBottom: "0.75rem",
    minHeight: "auto",
  },
};

const cardSubtitle = {
  marginTop: "-.375rem",
};

const cardLink = {
  "& + $cardLink": {
    marginLeft: "1.25rem",
  },
};

export {
  //variables
  drawerWidth,
  drawerMiniWidth,
  transition,
  container,
  containerFluid,
  boxShadow,
  card,
  defaultFont,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  // old card header colors
  orangeCardHeader,
  greenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  roseCardHeader,
  blackCardHeader,
  // new card header colors
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  cardActions,
  cardHeader,
  defaultBoxShadow,
  title,
  cardTitle,
  cardSubtitle,
  cardLink,
};
