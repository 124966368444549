// @ts-nocheck
import React from "react";
import animationData from "./LoaderRing.json";
import LottieAnimation from "../LottieAnimation";

const LoaderRing = ({ height }) => (
  <LottieAnimation animationData={animationData} height={height} />
);

export default LoaderRing;
