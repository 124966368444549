// @ts-nocheck
// ##############################
// // // App styles
// #############################

import {
  drawerMiniWidth,
  transition,
  containerFluid,
} from "../../app-js-styles";

const baseLayoutStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "',
    },
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: "100%",
    overflow: "auto",
    position: "relative",
    ...transition,
    maxHeight: "100%",
    overflowScrolling: "touch",
  },
  content: {
    padding: "30px 0px",
    minHeight: "calc(100vh - 150px)",
  },
  container: { ...containerFluid },
  map: {
    marginTop: 120,
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`,
    },
  },
});

export default baseLayoutStyle;
